<template>
  <div class="paySuccess">
    <div class="passIconfont icon-fttongguowancheng success_icon"></div>
    <p class="success_tips">支付成功!</p>
    <div class="goods_info">
      <!-- <div class="goods_name  clearfix">
        <span class="goods_label fl">商品名称：</span>
        <span class="name fr">好多好多好多奥铂好</span>
      </div> -->
      <div class="goods_name  clearfix">
        <span class="goods_label fl">支付金额：</span>
        <span class="goods_price fr">{{amount}}</span>
      </div>
    </div>
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'

export default {
  data () {
    return {
      amount: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.amount = '¥' + this.changeMoney(this.$route.query.amount)
    },
    changeMoney (num) {
      var regexp = /(?:\.0*|(\.\d+?)0+)$/
      if (num > 1000000) {
        num = JSON.stringify(num).slice(0, JSON.stringify(num).length - 4) / 100
        return num + '万'
      } else {
        num = (num / 100).toFixed(2)
        num = num.replace(regexp, '$1')
        return num
      }
    },
  }
}
</script>


<style lang="scss">
.paySuccess {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .success_icon {
    width: 120px;
    height: 120px;
    font-size: 120px;
    color: #ff7391;
    margin: 0 auto;
    margin-top: 50px;
  }
  .success_tips {
    font-size: 20px;
    color: #575757;
    text-align: center;
    font-weight: 700;
    margin-top: 60px;
    font-family: "SimHei";
  }
  .tips {
    font-size: 20px;
    color: #575757;
    text-align: center;
    margin-top: 15px;
    font-family: "SimHei";
  }
}
.goods_info {
  width: 100%;
  margin-top: 30px;
  padding: 0 15px;
  box-sizing: border-box;

  .goods_name {
    font-size: 16px;
    border-bottom: 1px solid #e4e4e4;
    height: 50px;
    line-height: 60px;
    .goods_label {
      color: #a8a8a8;
    }
    .name {
      color: #575757;
    }
  }
}
.goods_price {
  font-size: 16px;
  color: #fb8c1e;
}
</style>
